<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品评论</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="toCreate">添加</el-button>
          <el-button type="text" @click="toCreate2">批量添加</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="商品id">
        <el-input v-model="productid" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="adminList" border="" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center" width="95"></el-table-column>
        <el-table-column prop="productid" label="所属商品ID" align="center" width="95"></el-table-column>


        <el-table-column prop="commentname" label="评论人" width="110"></el-table-column>
        <el-table-column label="头像" width="110" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.commentphoto">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="satisfaction" label="满意度" width="80"></el-table-column>
        <el-table-column prop="commentcontents" label="评论内容"></el-table-column>
        <el-table-column label="评论图" width="150" align="center">
          <template slot-scope="scope">
            <el-image style="width: 50px; height: 50px" v-for="(item, index) in scope.row.commentimgs" :key="index"
              :src="item" :preview-src-list="scope.row.commentimgs">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="orderno" label="关联订单号" width="180"></el-table-column>
        <el-table-column prop="addtime" label="评论日期" width="180"></el-table-column>
        <el-table-column prop="states" label="是否精选" width="60">
          <template slot-scope="scope">{{ ['否', '是'][scope.row.states] }}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="setStates(scope.row.id, 0, scope.row)"
              v-if="scope.row.states == 1">取消精选</el-button>
            <el-button type="success" size="mini" @click="setStates(scope.row.id, 1, scope.row)" v-else>设为精选</el-button>
            <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex"
        :total="pageTotal" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import {
  getListRequest,
  setStatesRequest,
  delRequest,
  setSortRequest
} from "@/api/zr_productcomment";
export default {
  name: "advlist",
  data() {
    return {
      productid: "",
      adminList: [],
      userName: "",
      pageIndex: 1,
      pageTotal: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getListRequest({
        productid: this.productid,
        pageNum: this.pageIndex,
        pageSize: 10
      }).then(res => {
        let data = res.data;
        this.adminList = data.data;
        this.pageTotal = data.count;

        this.adminList.map(item => {
          item.commentimgs = item.commentimgs.split(",");
        });
      });
    },

    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder
      }).then(res => { });
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states
      }).then(res => {
        row.states = states;
      });
    },
    toCreate() {
      this.$router.push({
        path: "/zr_productcomment/edit",
        query: {
          id: 0
        }
      });
    },
    toCreate2() {
      this.$router.push({
        path: "/zr_productcomment/addbatch",
        query: {
          id: 0
        }
      });
    },

    toEdit(id) {
      this.$router.push({
        path: "/zr_productcomment/edit",
        query: {
          id: id
        }
      });
    },

    onDelete(id) {
      this.$confirm("此操作将删除改行, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delRequest({ Id: id }).then(res => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: `成功`
            });
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
</style>

import request from '@/utils/request'

// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/zr_productcomment/getList',
    method: 'get',
    params
  })
}




// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/zr_productcomment/getdatil',
    method: 'get',
    params
  })
}

// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/zr_productcomment/del',
    method: 'get',
    params
  })
}

// 修改 
export function editbatch(data) {
  return request({
    url: '/admin/zr_productcomment/editbatch',
    method: 'post',
    data
  })
}


// 修改 
export function editRequest(data) {
  return request({
    url: '/admin/zr_productcomment/edit',
    method: 'post',
    data
  })
}


// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/zr_productcomment/setStates',
    method: 'get',
    params
  })
}

